import { siteMetadata } from "../../gatsby-config"
var CryptoJS = require("crypto-js")

export const isBrowser = () => typeof window !== "undefined"

const getStorageKeyName = () =>
{
  const { siteUrl } = siteMetadata
  var key = siteUrl + ":userData"
  return key
}

export const getUserParsedData = () =>
  isBrowser() && window.localStorage.getItem(getStorageKeyName())
    ? JSON.parse(decryptData(window.localStorage.getItem(getStorageKeyName())))
    : {}

export const getUserJSONData = () =>
  isBrowser() && window.localStorage.getItem(getStorageKeyName())
    ? decryptData(window.localStorage.getItem(getStorageKeyName()))
    : {}

export const loginUser = (userID, userName, login_type) => {
  const userData = {
    id: userID,
    name: userName,
    loginType: login_type,
  }
  return setUserData(JSON.stringify(userData))
}

const setUserData = userData => {
  var encrypted = encryptData(userData)
  window.localStorage.setItem(getStorageKeyName(), encrypted)
}

const encryptData = userData => {
  var encrypted = CryptoJS.AES.encrypt(
    userData,
    process.env.GATSBY_LOGIN_PASSPHRASE
  ).toString()
  var base64 = Buffer.from(encrypted).toString("base64")
  return base64
}

const decryptData = savedData => {
  if (savedData !== "" || savedData !== null) {
    const decoded = Buffer.from(savedData, "base64").toString()
    var bytes = CryptoJS.AES.decrypt(
      decoded,
      process.env.GATSBY_LOGIN_PASSPHRASE
    )
    var decrypted = bytes.toString(CryptoJS.enc.Utf8)
    return decrypted
  }
  return {}
}

export const isLoggedIn = () => {
  const user = getUserParsedData()
  return !!user.id
}

// export const logout = callback => {
//   setUserData({})
//   callback()
// }

export const logout = () => {
  window.localStorage.setItem(getStorageKeyName(), "")
}
